import ApolloClient, {
  ApolloQueryResult,
  DocumentNode,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  FetchResult,
  MutationOptions,
  NormalizedCacheObject,
} from 'apollo-boost/lib/index'
import React from 'react'
import { OperationVariables, Query, QueryProps, QueryResult } from 'react-apollo'
import { ErrorCallback, parseGQLErrors } from './ApiError'

export type ClientType = ApolloClient<NormalizedCacheObject>

// prettier-ignore
export type QueryResultCtx<TResponse> = Partial<Pick<QueryResult<TResponse>, 'data' | 'error' | 'loading' | 'refetch' | 'variables'>>

// prettier-ignore
type QueryArgs<TData, TVariables> = Omit<QueryProps<TData, TVariables>, 'children'> & {
  query: DocumentNode
  defaultVariables?: Partial<TVariables>
  fetchMoreOpts?: (
    result: QueryResult<TData, TVariables>,
  ) => FetchMoreQueryOptions<TVariables, keyof TVariables> & FetchMoreOptions<TData, TVariables> | undefined
}

// prettier-ignore
type QueryComponentProps<TData, TVariables extends OperationVariables> = Omit<QueryProps<TData, TVariables>, 'query' | 'children'> & {
  onError?: ErrorCallback
  children(
    result: QueryResult<TData, TVariables>,
    args: { fetchMore?(): Promise<ApolloQueryResult<TData>> },
  ): React.ReactNode
}

export function buildQuery<TData, TVariables extends OperationVariables = OperationVariables>({
  fetchMoreOpts,
  defaultVariables,
  ...props
}: QueryArgs<TData, TVariables>): React.FC<QueryComponentProps<TData, TVariables>> {
  return ({ variables, onError, children, ...restProps }) => (
    <Query {...props} {...restProps} variables={{ ...defaultVariables, ...variables }}>
      {(res: any) => {
        if (res.error && res.error.graphQLErrors && res.error.graphQLErrors.length) {
          console.info({ resError: res.error })
          parseGQLErrors(res.error.graphQLErrors, onError)
        }
        const fetchOpts = fetchMoreOpts && fetchMoreOpts(res)
        return children(res, {
          fetchMore: fetchOpts && (() => res.fetchMore(fetchOpts)),
        })
      }}
    </Query>
  )
}

type MutationArgs<TData, TVariables> = MutationOptions<TData, TVariables>
// prettier-ignore
type MutateArgs<TData, TVariables, TMutateArgs> = Omit<MutationArgs<TData, TVariables>, 'mutation'> & {
  mutation: DocumentNode
  mutateOpts?(
    args: TMutateArgs & Partial<MutationArgs<TData, TVariables>>,
  ): Omit<MutationArgs<TData, TVariables>, 'mutation'>
}
type MutateFn<TData, TVariables, TMutateArgs> = (
  client: ClientType,
  args: TMutateArgs,
  mutateArgs?: Partial<MutationArgs<TData, TVariables>>,
  onError?: ErrorCallback,
) => Promise<FetchResult<TData>>

// prettier-ignore
export function mutateFunction<TData, TVariables extends OperationVariables = OperationVariables, TMutateArgs extends TVariables = TVariables>({
  mutation,
  mutateOpts,
  ...rest
}: MutateArgs<TData, TVariables, TMutateArgs>): MutateFn<TData, TVariables, TMutateArgs> {
  return async (client, args, mutateArgs = {}, onError) => {
    const argFn = mutateOpts || (variables => ({ variables }))

    // @ts-ignore
    const opts: MutationArgs<TData, TVariables> = {
      ...rest,
      ...argFn(args),
      ...(mutateArgs || {}),
      mutation,
    }
    const res = await client.mutate<TData, TVariables>(opts)
    if (res.errors) {
      parseGQLErrors(res.errors, onError)
    }

    return res
  }
}

export const formatCurrency =
  (defaultValue: string = '-') =>
  (n?: number | string | null) => {
    if (n === null || n === undefined || n === '' || Number.isNaN(n)) {
      return defaultValue
    }
    if (n === undefined) {
      return '-'
    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(parseFloat(n.toString()))
  }
